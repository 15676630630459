/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    'serve'         : 0,
    'zipFail'       : false,
    'areasDataA'    : null,
    'areasDataB'    : null,
    'zip'           : null,
    'address'       : null,
    'county'        : null,
    'subheading'    : null,
    'paragraph'     : null,
    'excluded'      : 0,
    'supportNumber' : '<a href="tel:18884283777">(888) 428-3777</a>',

    // All pages
    'common': {
      init: function() {

        $.get( "/wp-content/themes/pss/areasDataA.json", function( data ) {
          Sage.areasDataA = data;
        });

        $.get( "/wp-content/themes/pss/areasDataB.json", function( data ) {
          Sage.areasDataB = data;
        });

        $(".pss-fancybox").fancybox({
          autoSize: true,
          fitToView: true,
          openEffect  : 'elastic',
          closeEffect : 'elastic'
        });

        $(window).bind("load", function() {

          function zipMessaging() {

            if ( Sage.serve === 0 ) {
              Sage.subheading  = 'Serving Central & Southern California';
              Sage.paragraph   = 'Please enter the zip code for the location of your project.';
              $('.zip-form').addClass('display'); // show
              $('.quote-form').removeClass('display');
              $('.sorry-form').removeClass('display');
              $('.zip-form').removeClass('submitting');
            }

            if ( Sage.serve === 1 ) {
              Sage.subheading  = 'We serve your area!';
              Sage.paragraph   = 'Please tell us more about your overhead door, gate and security needs.';
              $('.zip-form').removeClass('display');
              $('.quote-form').addClass('display'); // show
              $('.sorry-form').removeClass('display');
              $('.zip-form').removeClass('submitting');
            }

            if ( Sage.serve === 2 ) {
              Sage.subheading  = 'We’re sorry!';
              Sage.paragraph   = 'Your project is outside of our current service range. <br />Please contact us at '+Sage.supportNumber+' if you have additional questions.';
              $('.zip-form').removeClass('display');
              $('.quote-form').removeClass('display');
              $('.sorry-form').addClass('display'); // show
              $('.zip-form').removeClass('submitting');
            }

            if ( Sage.serve === 3 ) { // unable to load exclusion list
              Sage.subheading  = 'We’re sorry!';
              Sage.paragraph   = 'An error occurred while trying to process your request. <br />Please try again or contact us at '+Sage.supportNumber+'.';
              $('.zip-form').removeClass('display');
              $('.quote-form').removeClass('display');
              $('.sorry-form').addClass('display'); // show
              $('.zip-form').removeClass('submitting');
            }

            if ( Sage.serve === 4 ) { // fdsasd
              Sage.subheading  = 'Thank you for your interest!';
              Sage.paragraph   = 'While we do not currently provide service in your area, our sister company Fence Factory should be able to assist you. Please give them a call at <a href="tel:18006133623">1-800-613-3623</a>.';
              $('.zip-form').removeClass('display');
              $('.quote-form').removeClass('display');
              $('.sorry-form').addClass('display'); // show
              $('.zip-form').removeClass('submitting');
            }

            $(".zip-subheading").html(Sage.subheading);
            $(".zip-paragraph").html(Sage.paragraph);

          }

          function checkZip(zip) {

            console.log('enters checkzip');
            var deferred = $.Deferred();
            var found = 0;

            if ( zip !== undefined && zip !== null && zip !== '' ) {

              // search inclusion lists
              try {
                
                $.each( Sage.areasDataA.inclusions, function( key, value ) {
                  if ( value == zip ) {
                    found = 1;
                    deferred.resolve(1);
                    console.log('serve 1 areasDataA match');
                  }
                });

                $.each( Sage.areasDataB.inclusions, function( key, value ) {
                  if ( value == zip ) {
                    found = 1;
                    deferred.resolve(4);
                    console.log('serve 4 areasDataB match');
                  }
                });

              } catch(e) {
                deferred.reject(3);
                console.log('error caught when on $each, reject 3');
              }

            } else {
              deferred.reject(0);
              console.log('no zip provided to checkZip, reject 0');
            }

            if ( found === 0 ) {
              console.log('not found reject 2');
              deferred.reject(2);
            }

            console.log('about to return');
            return deferred.promise();

          } // zipSubmit()


          $(document).on('click', '.zip-form-submit', function(e) {
            e.preventDefault();

            console.log('button clicked 3');

            var zip = $("#project_zip").val();
            var promise = checkZip(zip);
            promise.always(function (serveValue) {
              console.log('checkZip Done');
              Sage.serve = serveValue;
              console.log(Sage.serve + ' -- serveValue after checkZip');
              zipMessaging();
            });
        
          });


        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        var $client_carousel = $('.client-carousel').flickity({
          cellSelector    : '.gallery-cell',
          imagesLoaded    : true,
          percentPosition : false,
          cellAlign       : 'center', 
          contain         : true,
          wrapAround      : true,
          autoPlay        : true
        });

        var $gallery = $('.main-carousel').flickity({
          cellSelector    : '.gallery-cell',
          imagesLoaded    : true,
          percentPosition : false,
          cellAlign       : 'center', 
          contain         : true,
          wrapAround      : true,
          autoPlay        : 7000
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
